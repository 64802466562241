import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faEnvelope } from "@fortawesome/free-solid-svg-icons"

const ShopItemTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <section className="section">
        <article className="container">
          <div className="columns">
            <div className="column">
              <figure className="has-text-centered">
                <img width="512" src={post.frontmatter.featuredimage}></img>
              </figure>
            </div>
            <div className="column">
              <h1 className="title is-1" itemProp="headline">{post.frontmatter.title}</h1>
              <div className="content">
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.html,
                  }}
                  >    
                </p>
                <div className="control">
                  <label class="label"for="quantity">
                    Quantité :  
                  </label>
                  <input type="number" id="quantity" name="quantity" placeholder="1" min="1" max="100"></input>
                </div>
              </div>
              <div className="title is-2 has-text-success" itemProp="headline">
                {(post.frontmatter.price).toLocaleString('fr-FR', {style: 'currency', currency: 'EUR'})}
              </div>
              <p className="content has-text-danger">Veuillez utiliser le formulaire de contact pour demander l'achat de cet article.</p>
              <div className="buttons">
                <Link to="/contact" activeClassName="button is-primary" className="button is-primary" disabled>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faCartPlus} color="white" />
                  </span>
                  <strong>Ajouter au panier</strong>
                </Link>
                <Link to="/contact" activeClassName="button is-success" className="button is-success">
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faEnvelope} color="white" />
                  </span>
                  <strong>Nous Contacter</strong>
                </Link>
              </div>
            </div>
          </div>
          <hr />
        </article>
        <nav className="shop-item-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </section>
    </Layout>
  )
}

export default ShopItemTemplate

export const pageQuery = graphql`
  query ShopItemBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        templateKey
        title
        price
        date(formatString: "DD MMMM YYYY", locale: "fr")
        featuredimage
      }
      html
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`